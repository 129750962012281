<template>
  <div>
    <div class="top-banner">
      <div class="bg"></div>
      <div class="ban">
        <div class="title-big">American Delivery Service </div>
        <div class="title-small">
          <div>Experience hassle-free, timely delivery of your products.
          </div>
          <div>We allow you to add at most 200 packages in one order. </div>
        </div>
      </div>
    </div>
    <div class="cont">
      <div class="title">The most suitable vehicle for the task</div>
      <div class="des">Vehicles play a crucial role in the operations of our company. They are
        the backbone
        of the transportation and
        are responsible for the timely delivery of goods and products from one place to another. ADS relies heavily on our
        fleet of vehicles to ensure that our customers' needs are met.
      </div>
      <div class="des">
        When it comes to choosing the right vehicles, several factors need to be considered, such as the type of goods
        being
        transported, the distance of the delivery, and the frequency of the trips. Some of the most commonly used vehicles
        in our company are vans, cars, SUV's and trucks.
      </div>
      <div class="des">
        ADS typically matches the size and weight of the items being transported with the appropriate type of vehicle.
        Smaller items are usually delivered using cars or SUV's, while larger items require high-capacity vans or trucks.
        In
        some cases, adjustable loading docks and ramps can be used to facilitate the loading and unloading of these larger
        items. For customers with more complex logistics needs, for example, LTL, they can directly submit the form from
        the
        retailer portal when they are approved to be our registered customers.
      </div>
      <div class="des">
        By taking care of the critical details of the delivery process, ADS helps ease the worries of their customers and
        ensures that their items are delivered safely and on time.
      </div>
      <!-- 图片 -->
      <div class="images">
        <div class="img-item" style="text-align: center;">
          <img src="../../../assets/images/home/van.png" alt="">
        </div>
        <div class="img-item" style="text-align: center;">
          <img src="../../../assets/images/home/hatchback.jpg" alt="">
        </div>
        <div class="img-item" style="text-align: center;">
          <img src="../../../assets/images/home/suv.jpeg" alt="">
        </div>
        <div class="img-item" style="text-align: center;">
          <img src="../../../assets/images/home/truck.jpg" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="less" scoped>
.top-banner {
  box-sizing: border-box;
  position: relative;
  height: 538px;
  overflow: hidden;

  .bg {
    background-image: url('../../../assets/images/home/cangku2.png');
    background-size: cover;
    background-position: 50% 50%;
    height: 540px;
    filter: blur(2px);
  }

  .ban {
    width: 700px;
    position: absolute;
    z-index: 9;
    top: 50%;
    right: 50%;
    transform: translate(50%, -70%);
  }

  .title-big {
    font-weight: 500;
    font-size: 56px;
    color: #fff;
    text-align: center;
    line-height: 64px;
    padding-top: 166px;
  }

  .title-small {
    margin-top: 28px;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    line-height: 28px;
    color: #fff;
  }
}

.cont {
  width: 1300px;
  margin: 0 auto;
  padding: 60px 40px;
  box-sizing: border-box;

  .title {
    font-weight: 300;
    font-size: 40px;
    color: #333;
  }

  .des {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.8;
    color: var(--color-text-2);
    padding-top: 16px;
  }

  .images {
    padding-top: 100px;
    box-sizing: border-box;
    display: flex;

    .img-item {
      width: 25%;

      img {
        width: 93%;
        border-radius: 8px;
        height: 100%;
      }
    }
  }
}

.about-us {
  text-align: center;
  padding-top: 100px;
  font-weight: 300;
  font-size: 56px;
  line-height: 53px;
  color: #333333;
}
</style>
